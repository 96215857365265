//--------------------------------------------------------------
// Imports Section
//--------------------------------------------------------------
import * as React                       from 'react';
import Diploma                          from "./Diploma"


//--------------------------------------------------------------
// Component Section
//--------------------------------------------------------------
const Frames = (props) => {

    //----------------------------------------------------------
    // Render Section
    //----------------------------------------------------------
    return (
        <>
            {/* Java */}
            <Diploma
                position={[-220,205,447.5]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/Java/advanced_java.png"
                type={1}
            />

            <Diploma
                position={[-350,205,447.5]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/Java/carrera_java.png"
                type={1}
            />

            <Diploma
                position={[-480,205,447.5]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/Java/testing_java.png"
                type={1}
            />

            <Diploma
                position={[-610,205,447.5]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/Java/java_se.png"
                type={1}
            />

            <Diploma
                position={[-220,90,447.5]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/Java/oop_java.png"
                type={1}
            />


            {/* Angular */}
            <Diploma
                position={[250,205,132.5]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/Angular/angular_career.jpg"
                type={2}
            />

            <Diploma
                position={[400,205,132.5]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/Angular/angular_avanzado.jpg"
                type={2}
            />

            <Diploma
                position={[250,90,132.5]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/Angular/angular.jpg"
                type={2}
            />

            <Diploma
                position={[400,90,132.5]}
                rotation={[0,0,0]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/Angular/mean.jpg"
                type={2}
            />

            <Diploma
                position={[500,205,195]}
                rotation={[0,(-Math.PI / 2),0]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/Angular/angular_expert.jpg"
                type={2}
            />

            <Diploma
                position={[500,205,375]}
                rotation={[0,(-Math.PI / 2),0]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/Angular/angular_js.jpg"
                type={2}
            />

            <Diploma
                position={[500,205,555]}
                rotation={[0,(-Math.PI / 2),0]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/Angular/ngrx_1.jpg"
                type={2}
            />

            <Diploma
                position={[500,90,195]}
                rotation={[0,(-Math.PI / 2),0]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/Angular/rxjs.jpg"
                type={2}
            />

            <Diploma
                position={[500,90,375]}
                rotation={[0,(-Math.PI / 2),0]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/Angular/rxjs_2.jpg"
                type={2}
            />

            <Diploma
                position={[500,90,555]}
                rotation={[0,(-Math.PI / 2),0]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/Angular/angular_components.jpg"
                type={2}
            />

            <Diploma
                position={[300,205,660]}
                rotation={[0,(-Math.PI),0]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/Angular/pwa.jpg"
                type={2}
            />

            <Diploma
                position={[450,205,660]}
                rotation={[0,(-Math.PI),0]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/Angular/angular_core_deep_dive.jpg"
                type={2}
            />

            <Diploma
                position={[450,90,660]}
                rotation={[0,(-Math.PI),0]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/Angular/pluralsight_angular.png"
                type={2}
            />

            <Diploma
                position={[300,90,660]}
                rotation={[0,(-Math.PI),0]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/Angular/angular_4.png"
                type={2}
            />



            {/* Ionic */}
            <Diploma
                position={[-700,205,950]}
                rotation={[0,(Math.PI/2),0]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/Ionic/ionic_basico.jpg"
                type={2}
            />

            <Diploma
                position={[-700,205,800]}
                rotation={[0,(Math.PI/2),0]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/Ionic/ionic.jpg"
                type={2}
            />

            {/* Databases */}
            <Diploma
                position={[500,205,732]}
                rotation={[0,(-Math.PI/2),0]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/Databases/bases_de_datos_fundamentos.jpg"
                type={2}
            />

            <Diploma
                position={[500,205,902]}
                rotation={[0,(-Math.PI/2),0]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/Databases/SQLAnalyst.jpg"
                type={2}
            />

            <Diploma
                position={[500,90,732]}
                rotation={[0,(-Math.PI/2),0]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/Databases/mongodb_basico.jpg"
                type={2}
            />

            <Diploma
                position={[500,90,902]}
                rotation={[0,(-Math.PI/2),0]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/Databases/bd_2018.png"
                type={2}
            />


            {/* React */}
            <Diploma
                position={[250,205,-418.5]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/React/react.jpg"
                type={2}
            />

            <Diploma
                position={[400,205,-418.5]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/React/react_turbo.jpg"
                type={2}
            />

            <Diploma
                position={[250,90,-418.5]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/React/react_avanzado2.png"
                type={2}
            />

            <Diploma
                position={[400,90,-418.5]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/React/react_avanzado.jpg"
                type={2}
            />

            <Diploma
                position={[500,205,-355]}
                rotation={[0,(-Math.PI / 2),0]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/React/react_turbo2.jpg"
                type={2}
            />

            <Diploma
                position={[500,205,-175]}
                rotation={[0,(-Math.PI / 2),0]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/React/programacion_funcional.jpg"
                type={2}
            />

            <Diploma
                position={[500,205,5]}
                rotation={[0,(-Math.PI / 2),0]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/React/oop.jpg"
                type={2}
            />

            <Diploma
                position={[500,90,-355]}
                rotation={[0,(-Math.PI / 2),0]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/React/unit_test.jpg"
                type={2}
            />

            <Diploma
                position={[500,90,-175]}
                rotation={[0,(-Math.PI / 2),0]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/React/fundamentos_javascript.jpg"
                type={2}
            />

            <Diploma
                position={[500,90,5]}
                rotation={[0,(-Math.PI / 2),0]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/React/threejs.jpg"
                type={2}
            />

            {/* .NET */}
            <Diploma
                position={[-220,205,-370]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/NET/carrera_net.png"
                type={1}
            />

            <Diploma
                position={[-350,205,-370]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/NET/c_sharp_net_core.jpg"
                type={1}
            />

            <Diploma
                position={[-480,205,-370]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/NET/aspnet_core.jpg"
                type={1}
            />

            <Diploma
                position={[-610,205,-370]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/NET/c_sharp_basico_2017.jpg"
                type={1}
            />

            <Diploma
                position={[-350,90,-370]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/NET/fundamentos_c_sharp.jpg"
                type={1}
            />

            <Diploma
                position={[-610,90,-370]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/NET/microservices_1.jpg"
                type={1}
            />

            <Diploma
                position={[-480,90,-370]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/NET/microservices_2.jpg"
                type={1}
            />

            <Diploma
                position={[-700,205,-200]}
                rotation={[0,(Math.PI/2),0]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/NET/mcad.jpg"
                type={2}
            />

            <Diploma
                position={[-700,205,-50]}
                rotation={[0,(Math.PI/2),0]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/NET/mcp.jpg"
                type={2}
            />

            <Diploma
                position={[-700,90,-200]}
                rotation={[0,(Math.PI/2),0]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/NET/vb_enterprise.jpg"
                type={2}
            />


            <Diploma
                position={[-700,90,-50]}
                rotation={[0,(Math.PI/2),0]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/NET/vb_enterprise.jpg"
                type={2}
            />

            {/* Node */}
            <Diploma
                position={[-220,205,410]}
                rotation={[0,(Math.PI),0]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/Node/nodeJs_platzi.jpg"
                type={1}
            />

            <Diploma
                position={[-220,90,410]}
                rotation={[0,(Math.PI),0]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/Node/nodeJs_basico_platzi.jpg"
                type={1}
            />

            <Diploma
                position={[-350,205,410]}
                rotation={[0,(Math.PI),0]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/Node/graphql.jpg"
                type={1}
            />

            <Diploma
                position={[-350,90,410]}
                rotation={[0,(Math.PI),0]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/Node/graphql_basico_platzi.jpg"
                type={1}
            />

            {/* DevOps */}
            <Diploma
                position={[-205,205,704]}
                rotation={[0,(Math.PI),0]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/DevOps/terminal.jpg"
                type={2}
            />

            {/* DevOps */}
            <Diploma
                position={[-700,205,-480]}
                rotation={[0,(Math.PI/2),0]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/General/aprendizaje_efectivo.jpg"
                type={2}
            />

            <Diploma
                position={[-700,90,-480]}
                rotation={[0,(Math.PI/2),0]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/General/canvas.jpg"
                type={2}
            />

            <Diploma
                position={[-700,205,-630]}
                rotation={[0,(Math.PI/2),0]}
                externalColor="#3e2e23"
                internalColor="#3e2e23"
                image="/images/Diplomas/General/slack.png"
                type={2}
            />


        </>
    )

}


//--------------------------------------------------------------
// Exports Section
//--------------------------------------------------------------
export default Frames
